// eslint-disable
import { useEffect, useRef, useState, useCallback } from 'react';
// import AWS from 'aws-sdk';
// import AmazonS3URI from 'amazon-s3-uri';

// Custom hook to implement setInterval

const shift = 3;

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return console.error('Delay cant be null');
  }, [delay]);
};

// Custom hook to implement setTimeout

export const useTimeout = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
    return console.error('Delay cannot be null');
  }, [delay]);
};

export const apiValidation = (res, payload = 'result') => {
  if (res && res.success === 1) {
    return res[payload];
  }
  return console.error('The API has failed');
};

// function to implement global color styles

export const setGlobalColors = (primary, light, lighter, superlight) => {
  document.body.style.setProperty('--primary-blue', primary);
  document.body.style.setProperty('--lighter-blue', light);
  document.body.style.setProperty('--lightest-blue', lighter);
  document.body.style.setProperty('--superlight-blue', superlight);

  // adding theme color of client
  const oldThemeColorMeta = document.querySelector('meta[name="theme-color"]');
  // console.log(oldThemeColorMeta, 'old tag');
  const meta = document.createElement('meta');
  meta.setAttribute('name', 'theme-color');
  meta.setAttribute('content', primary);
  document.getElementsByTagName('head')[0].appendChild(meta);
  document.head.removeChild(oldThemeColorMeta);
  document.head.appendChild(meta);
};

// function to dynamically change document title

export function changeFaviconAndDocumentTitle(favicon, documentTitle) {
  [
    { id: 'dynamic-favicon', rel: 'icon' },
    { id: 'apple-icon', rel: 'apple-touch-icon' },
  ].forEach((elem) => {
    const link = document.createElement('link');
    const oldLink = document.getElementById(elem.id);
    link.id = elem.id;
    link.rel = elem.rel;
    link.href = favicon;
    link.type = 'image/x-icon';

    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  });
  if (documentTitle) document.title = documentTitle;

  // const link = document.createElement('link');
  // const oldLink = document.getElementById('dynamic-favicon');
  // link.id = 'dynamic-favicon';
  // link.rel = 'shortcut icon';
  // link.href = favicon;
  // document.title = documentTitle;

  // if (oldLink) {
  //   document.head.removeChild(oldLink);
  // }
  // document.head.appendChild(link);
}

// function to check whether development or environment

export function prodOrDev() {
  if (process.env.NODE_ENV === 'development') return 'development';
  if (process.env.NODE_ENV === 'production') return 'production';
  return 'test';
}

// function that sorts according to given parameter (Object key)

export const propComparator = (propName) => (a, b) =>
  a[propName] === b[propName] ? 0 : a[propName] < b[propName] ? -1 : 1;

export const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

// // function to validate file. Ideally should be server side but ab kya kar sakte h lol.

// export const verifyFileExtension = (type, acceptedFile) => {
//   const typeRegex = new RegExp(acceptedFile.replace(/\*/g, '.*').replace(/,/g, '|'));
//   return typeRegex.test(type);
// };

export default function useWindowDimensions() {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const urlify = (text) => {
  if (!text) return '';

  const urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.replace(urlRegex, function (url) {
    return isWebView
      ? `<a href="${url}" >${url}</a>`
      : `<a href="${url}" target="_blank">${url}</a>`;
  });
};

export const detectNextLine = (text) => {
  if (!text) return '';
  return text
    .split('\n')
    .map((ele) => `<p style='margin-bottom: 0px'>${ele}</p>`)
    .join('');
};

export const generatePreSignedUrl = (url) => {
  return url;
};

export const copyToClipboardHybrid = (text, showToast = true) => {
  if (isWebView && typeof window.Android.copyToClipboard !== 'undefined') {
    window.Android.copyToClipboard(text);
    // if (showToast) {
    //   window.Android.showToast('Copied to clipboard');
    // }
  } else {
    if (document.hasFocus()) {
      navigator.permissions.query({ name: 'clipboard-write' }).then((permissionStatus) => {
        console.log(permissionStatus.state); // 'granted', 'prompt', or 'denied'
        if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
          navigator.clipboard
            .writeText(text)
            .then(() => console.log('done'))
            .catch((err) => console.error(err));
        } else {
          console.error('Clipboard permissions denied.');
        }
      });
    }
  }
};

export const timestampToHumanDate = (timestamp, type) => {
  // Convert timestamp to milliseconds
  const milliseconds = timestamp * 1000;

  // Create a new Date object
  const date = new Date(milliseconds);

  // Array of month names
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Get day, month, and year
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Format the date
  if (type === 'num') {
    return `${day.toString().padStart(2, '0')} ${pad(+monthIndex + 1)} ${year}`;
  }
  return `${day.toString().padStart(2, '0')} ${
    monthNames[monthIndex]
  } ${year} at ${hours}:${minutes}`;
};

export const shareThis = (url, content, title = 'Look at this awesome course!') => {
  if (navigator.share) {
    navigator
      .share({
        title,
        // eslint-disable-next-line
        text: content,
        url,
      })
      .then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
  } else if (isWebView) {
    window.Android.share(title, `${content} ${url}`);
  } else {
    navigator.clipboard.writeText(url);
  }
};

export const formatDateInEnglish = (date) => {
  if (!date) return null;
  if (date == 'null') return null;
  const format1 = String(new Date(+date * 1000));
  console.log(format1, 'format1');
  const formatArr = format1.split(' '); // [month, date, year]
  const finalFormat = [formatArr[2], formatArr[1], formatArr[3]];
  return finalFormat.join(' ');
};

let isWV = false;

if (typeof window.Android === 'undefined') {
  isWV = false;
} else {
  isWV = true;
}

export const isWebView = isWV;

// export const useDidMountEffect = (func, deps) => {
//   const didMount = useRef(false);

//   useEffect(() => {
//     if (didMount.current) func();
//     else didMount.current = true;
//   }, deps);
// };

const isTouchEvent = (event) => {
  return 'touches' in event;
};

export const hslToHex = (h, s, ll) => {
  const l = ll / 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const isIOS = () => {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
};

export function isIphoneOrIpad() {
  const { userAgent } = navigator;

  // Check for iPhone or iPad in the user agent string
  const isIphone = /iPhone/.test(userAgent);
  const isIpad = /iPad/.test(userAgent);

  // Ensure the device is not emulated by checking for real touch support
  const isRealDevice =
    window.matchMedia('(pointer: coarse)').matches &&
    window.innerWidth > 0 &&
    window.innerHeight > 0;

  // Return true only if it is a real iPhone or iPad device and not emulated
  return (isIphone || isIpad) && isRealDevice;
}

let isChromeB = true;
const ua = navigator.userAgent.toLowerCase();
if (ua.indexOf('safari') != -1) {
  if (ua.indexOf('chrome') > -1) {
    isChromeB = true;
  } else {
    isChromeB = false;
  }
}
const isAp = window.navigator.standalone;
export const isIos1 = !isChromeB && isAp;

const preventDefault = (event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

// export const convertXlsIntoJson = (oEvent) => {
//   // Get The File From The Input
//   const oFile = oEvent.target.files[0];
//   const sFilename = oFile.name;
//   // Create A File Reader HTML5
//   const reader = new FileReader();

//   // Ready The Event For When A File Gets Selected
//   reader.onload = function (e) {
//     const data = e.target.result;
//     const cfb = XLS.CFB.read(data, { type: 'binary' });
//     const wb = XLS.parse_xlscfb(cfb);
//     // Loop Over Each Sheet
//     wb.SheetNames.forEach(function (sheetName) {
//       // Obtain The Current Row As CSV
//       const sCSV = XLS.utils.make_csv(wb.Sheets[sheetName]);
//       const oJS = XLS.utils.sheet_to_row_object_array(wb.Sheets[sheetName]);

//       // $('#my_file_output').html(sCSV);
//       console.log(oJS, 'myReadData');
//     });
//   };

//   // Tell JS To Start Reading The File.. You could delay this if desired
//   reader.readAsBinaryString(oFile);
// };

// const makeRepeated = (arr, repeats) => Array.from({ length: repeats }, () => arr).flat();
// makes array repeats itself n times....makesRepeat(arr, 5)
export function extractBlobDetails(blobUrl) {
  if (
    blobUrl.includes('azurefrontdoor') ||
    blobUrl.includes('blob.core.windows.net') ||
    blobUrl.includes('azureedge.net')
  ) {
    try {
      // Parse the URL
      const url = new URL(blobUrl);

      // Get the hostname and path
      const pathParts = url.pathname.split('/').filter(Boolean);

      // Extract container name and blob path
      const containerName = pathParts[0];
      const blobPath = pathParts.slice(1).join('/');

      return {
        containerName,
        blobName: blobPath,
      };
    } catch (error) {
      console.error('Invalid URL:', error);
      return {};
    }
  } else if (blobUrl.includes('cloudfront.net/')) {
    const modifiedUrl = decodeURIComponent(blobUrl);
    // var uri = url1.replace('https://userfiles-ingenium.s3.ap-south-1.amazonaws.com/', 'https://d1lo0kv7po0fz1.cloudfront.net/');
    const k = decodeURIComponent(blobUrl).split('cloudfront.net/')[1];
    let bucket;
    let key;
    const region = 'ap-south-1';
    if (modifiedUrl.includes('https://d1lo0kv7po0fz1.cloudfront.net')) {
      bucket = 'userfiles-ingenium';
      key = k;
    } else if (modifiedUrl.includes('https://d150gnfeuxp7r1.cloudfront.net')) {
      bucket = 'question-images-ingenium';
      key = k;
    } else if (modifiedUrl.includes('https://d2tdtly6rg7g3p.cloudfront.net')) {
      bucket = 'vc-10001093-109316679702';
      key = k;
    }

    return { bucket, key, region, path: key, blobName: key };
  } else {
    return {};
  }
}

export const changeLinkToCloudfront = (link) => {
  let newLink = link;
  if (link.includes('question-images-ingenium')) {
    newLink = link.replace(
      'https://s3.ap-south-1.amazonaws.com/question-images-ingenium/',
      'https://d150gnfeuxp7r1.cloudfront.net/',
    );
  } else if (link.includes('userfiles-ingenium')) {
    newLink = link.replace(
      'https://userfiles-ingenium.s3.ap-south-1.amazonaws.com/',
      'https://d1lo0kv7po0fz1.cloudfront.net/',
    );
  } else if (link.includes('vc-10001093-109316679702')) {
    newLink = link.replace(
      'https://vc-10001093-109316679702.s3.ap-south-1.amazonaws.com/',
      'https://d2tdtly6rg7g3p.cloudfront.net/',
    );
  }
  return newLink;
};

export function encrypt(key) {
  const base64Table = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

  // Step 1: Apply Caesar Cipher
  // eslint-disable
  const shifted = [...key]
    .map((char) => {
      if (/[a-zA-Z0-9]/.test(char)) {
        const charCode = char.charCodeAt(0);
        let str = '';
        if (char >= '0' && char <= '9') {
          str = String.fromCharCode(((charCode - 48 + shift) % 10) + 48);
        } else if (char >= 'A' && char <= 'Z') {
          str = String.fromCharCode(((charCode - 65 + shift) % 26) + 65);
        } else if (char >= 'a' && char <= 'z') {
          str = String.fromCharCode(((charCode - 97 + shift) % 26) + 97);
        }
        return str;
      }
      return char; // Non-alphanumeric characters remain unchanged
    })
    .join('');

  // Step 2: Custom Base64 Encoding
  let binary = '';
  for (const char of shifted) {
    binary += char.charCodeAt(0).toString(2).padStart(8, '0');
  }

  let base64Encoded = '';
  for (let i = 0; i < binary.length; i += 6) {
    const chunk = binary.slice(i, i + 6).padEnd(6, '0'); // Pad incomplete chunks with zeros
    base64Encoded += base64Table[parseInt(chunk, 2)];
  }

  return base64Encoded;
}

export function dc(encryptedKey) {
  const base64Table = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

  // Step 1: Custom Base64 Decoding
  let binary = '';
  for (const char of encryptedKey) {
    const index = base64Table.indexOf(char);
    if (index !== -1) {
      binary += index.toString(2).padStart(6, '0');
    }
  }

  const decoded = [];
  for (let i = 0; i < binary.length; i += 8) {
    const byte = binary.slice(i, i + 8);
    if (byte.length === 8) {
      // Ensure only complete bytes are processed
      decoded.push(String.fromCharCode(parseInt(byte, 2)));
    }
  }

  const decodedString = decoded.join('');

  // Step 2: Reverse the Caesar Cipher
  // eslint-disable
  const original = [...decodedString]
    .map((char) => {
      if (/[a-zA-Z0-9]/.test(char)) {
        const charCode = char.charCodeAt(0);
        let str = '';
        if (char >= '0' && char <= '9') {
          str = String.fromCharCode(((charCode - 48 - shift + 10) % 10) + 48);
        } else if (char >= 'A' && char <= 'Z') {
          str = String.fromCharCode(((charCode - 65 - shift + 26) % 26) + 65);
        } else if (char >= 'a' && char <= 'z') {
          str = String.fromCharCode(((charCode - 97 - shift + 26) % 26) + 97);
        }
        return str;
      }
      return char; // Non-alphanumeric characters remain unchanged
    })
    .join('');

  return original;
}

function detectDevice() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const platform = window.navigator.platform.toLowerCase();

  // Specific detection for iPhone
  const isIPhone =
    /iphone/.test(userAgent) &&
    (/iphone/.test(platform) || /iphone/.test(userAgent)) &&
    // Ensure it's not just an iPod or other device
    !/ipod/.test(userAgent);

  // Specific detection for iPad
  const isIPad =
    (/ipad/.test(userAgent) ||
      // Check for iPad-specific user agent patterns
      (/macintosh/.test(userAgent) && 'ontouchend' in document)) &&
    // Ensure touch capability and avoid mistaking for Mac
    window.screen.width !== window.screen.height;

  if (isIPhone) {
    return 'iPhone';
  }

  if (isIPad) {
    return 'iPad';
  }

  return null;
}

// Usage examples
export function checkDeviceIsIos() {
  const device = detectDevice();

  switch (device) {
    case 'iPhone':
      return true;
      break;
    case 'iPad':
      return true;
      break;
    default:
      return false;
  }
}
